export const ACTION_TYPES = {
  reset: "RESET",
  setLoggedOut: "SET_LOGGED_OUT",
  setLoggedIn: "SET_LOGGED_IN",
  setLogInError: "SET_LOGIN_ERROR",
  setTriggerRefresh: "SET_TRIGGER_REFRESH",
  setSelectedAppointment: "SET_SELECTED_APPOINTMENT",
  setBookedAppointment: "SET_BOOKED_APPOINTMENT",
  setShowSpinner: "SET_SHOW_SPINNER",
  setFreeSlotList: "SET_FREE_SLOT_LIST",
  setCoupons: "SET_COUPONS",
  setClaimToken: "SET_CLAIM_TOKEN",
  resetClaimToken: "RESET_CLAIM_TOKEN",
  setStartDateTime: "SET_START_DATE_TIME",
  resetStartDateTime: "RESET_START_DATE_TIME",
  setFocusOnList: "SET_FOCUS_ON_LIST",
  setInfoboxState: "SET_INFOBOX_STATE",
  resetInfoboxState: "RESET_INFOBOX_STATE",
  setBookedList: "SET_BOOKED_LIST",
  setStartDate: "SET_START_DATE",
  setEndDate: "SET_END_DATE",
  setBookingHistoryErrorMessage: "SET_BOOKING_HISTORY_ERROR_MESSAGE",
  setLoggedInUserName: "SET_LOGGED_IN_USERNAME",
};
